
$text-color-light: #E0CF83;

#about {
  width: 100%;
  max-width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  z-index: 100;

  &::before {
    display: block;
    content: '';
    background: black;
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 200%);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -50;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -100;
    position: absolute;
  }

  .home_cta {
    margin-top: 50px;
    margin-bottom: auto;
    text-align: left;
    color: #E0CF83;

    h1 {
      font-weight: 800;
      color: #E0CF83;
      font-size: 8rem;
      padding-bottom: 50px;
      text-transform: uppercase;

      @media (screen and  max-width: 720px) {
        font-size: 2rem;
      }
    }
  }

  .navbar {
    background-color: transparent;
    border-top: 2px dotted #E0CF83;
    display: flex;
    justify-content: space-between;

    padding-top: 50px;
    padding-left: 150px;
    padding-right: 150px;


    @media (screen and  max-width: 720px) {
      img {
        width: 100px;
      }
    }
  }
}

#about {
  .about_image {
    background-image: url("../images/IMG_0626.jpg");
    background-position: center center;
    background-size: cover;
    //background-attachment: fixed;
    background-repeat: no-repeat;
    display: flex;

    .text {
      margin-top: auto;
      margin-bottom: auto;
      display: flex;

      h2 {
        color: white;
        padding: 10px;
        font-weight: 800;
        letter-spacing: 0.2rem;
        span {
          background: #7E7342;
        }

      }
    }
    @media (screen and  max-width: 720px) {
      background-position: center;
      //background-attachment: fixed;
      background-size: auto;
      padding-top: 100px;
      padding-bottom: 100px;
      background-color: black;
    }
  }

  .about_text {
    display: flex;
    background: #7E7342;
    color: white;
    padding: 50px;
    .about_text_info {
      margin-top: auto;
      margin-bottom: auto;
    }
    @media (screen and  max-width: 720px) {
      padding: 20px;
    }

  }
}
