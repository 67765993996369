#sg-next-level-footer {
  background: black;
  color: white;

  #logo {
    margin-bottom: 50px;
    width: 150px;
  }

  .footer-section {
    background-color: #332E1E;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .footer-section-end {
    background-color: #1c1a0f;
    padding-bottom: 50px;

  }

  .footer-menu {
    margin-bottom: 50px;

    a {
      color: white;
    }
  }

  .footer-end {
    padding-top: 100px;
  }

  .social-sites {
    display: flex;
    justify-content: end;

    img {
      width: 40px;
    }

    .item:not(:last-child) {
      padding-right: 10px;
    }

    .item:hover {
      opacity: 50%;
    }
  }

  #mc_embed_signup {
    background: transparent;
    font-family: inherit;

    #mc-embedded-subscribe-form div.mce_inline_error {
      background-color: transparent;
    }

    form {
      margin: 0;
    }

    .indicates-required {}

    h2 {
      margin-bottom: 0px;
      padding-top: 0px;
      margin-top: 0px;
    };

    p {
      font-size: 16px
    }

    .brandingLogo {
      opacity: 0;
    }

    div.mc-field-group {
      width: 100%;
    }

    #mc-embedded-subscribe {
      background-color:  $text-color-light;
      color: black;
      height: 50px;
    }

    div.mc-field-group input {
      border: 0px !important;
      height: 50px;
      border-radius: 0px;
    }

    span.helper_text {
      background-color: transparent;
    }

    #mce-responses {
      margin: 0;
    }
  }
}