#services {
  min-height: 100%;
  background-color: #7E7342;

  h1 {
    color: #292412;
  }

  .service-card {
    background-color: #FFEE9F;
    border-radius: 5px;
    min-height: 350px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    color: black;

    p {
      margin-top: auto;
    }
  }
}

#membership {
  background-color: #7E7342;

  h1 {
    color: #292412;
  }

  .service-card {
    background-color: #FFEE9F;
    border-radius: 5px;
    min-height: 350px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    color: black;

    p {
      margin-top: auto;
    }
  }
}

