@import url('https://fonts.googleapis.com/css2?family=Ovo&display=swap');
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "_home.scss";
@import "_about.scss";
@import "_services.scss";
@import "_footer.scss";
@import "_navbar.scss";

html,body {
  font-family: 'Roboto Slab', serif;
  background: black;
}

html, body, main {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

nav {
  //position: absolute;
  top: 0;
  left: 0;
  svg {
    width: 300px;
  }
}

section {
  min-width: 100%;
  max-width: 100%;
  //width: 100%;
  //height: 100%;
}

#initial_cta {
  background: #14130b;
  height: 60px;
}

#about {
  .about_image {
    background-image: url("../images/IMG_0626.jpg");
    background-position: center center;
    background-size: cover;
    //background-attachment: fixed;
    background-repeat: no-repeat;
    display: flex;

    .text {
      margin-top: auto;
      margin-bottom: auto;
      display: flex;

      h2 {
        color: white;
        padding: 10px;
        font-weight: 800;
        letter-spacing: 0.2rem;
        span {
          background: #7E7342;
        }

      }
    }
    @media (screen and  max-width: 720px) {
      background-position: top center;
      //background-attachment: fixed;
      //background-size: fill;
      padding-top: 100px;
      padding-bottom: 100px;
      background-color: black;
      height: 100vh;
    }
  }

  .about_text {
    display: flex;
    background: #7E7342;
    color: white;
    padding: 50px;
    .about_text_info {
      margin-top: auto;
      margin-bottom: auto;
    }
    @media (screen and  max-width: 720px) {
      padding: 20px;
    }

  }
}

#filler {
  background-image: url("../images/isis.jpeg");
  background-attachment: fixed;
  height: 400px;

  @media (screen and  max-width: 720px) {
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

#testimonials {
  background-color: #14130b;
  color: white;

  #testimonial-text {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
  }

  #best-tennis-logo {
    background-color: black;
    display: flex;
    a {
      display: block;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
    }

    @media (screen and  max-width: 720px) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }
}

.mc-modal-bg {
  opacity: 0.86 !important;
}

#popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
}