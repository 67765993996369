.mobile-menu-expanded {
    position: fixed;
    z-index: 1000000000;
    background-color: black;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    .container {
        margin-top: 100px;
    }

    .menu-item {
        text-align: center;
        padding-bottom: 25px;
    }

    &--hidden {
        display: block;
    }
}


.sg-navbar-container {
    padding-top: 50px;

    .menu {
        display: flex;
        padding-top: 50px;

        a {
            color: $text-color-light;
            text-decoration: none;
            font-size: 18px;
            font-weight: 800;
            text-transform: uppercase;

            &:hover {
                color: white;
            }
        }

        .menu-item:not(:last-child) {
            padding-right: 20px;
        }

        @media (screen and max-width: 768px) {
            display: none !important;
        }
    }

    .navbar-brand img {
        @media (screen and max-width: 576px) {
            width: 120px;
        }
    }

    .mobile-menu {
        display: none;

        .gg-menu {
            transform: scale(var(--ggs,1))
        }
        .gg-menu,
        .gg-menu::after,
        .gg-menu::before {
            box-sizing: border-box;
            position: relative;
            display: block;
            width: 20px;
            height: 2px;
            border-radius: 3px;
            background: white
        }
        .gg-menu::after,
        .gg-menu::before {
            content: "";
            position: absolute;
            top: -6px
        }
        .gg-menu::after {
            top: 6px
        }

        @media (sreen and max-width:768px) {
            display: flex;
            padding-top:20px
        }
    }

    @media (sreen and min-width: 960px) {
        max-width: 960px;
    }

    @media (sreen and max-width:768px) {
        //padding: 50px;
    }

}