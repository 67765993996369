
$text-color-light: #E0CF83;

#home {
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 5px solid $text-color-light;
  box-sizing: border-box;
  z-index: 100;

  &::before {
    display: block;
    content: '';
    background: black;
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 200%);
    position: absolute;
    //top: 0;
    //left: 0;
    width: 100%;
    height: 100%;
    z-index: -50;
    //opacity: 0.7;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -100;
    position: absolute;
  }

  .home_cta {
    //margin-top: 1;
    //margin-bottom: auto;
    margin: auto;
    text-align: left;
    color: #E0CF83;

    h1 {
      font-weight: 800;
      color: #E0CF83;
      font-size: calc(10vh + 2vw);
      padding-bottom: 10px;
      text-transform: uppercase;

      @media (screen and  max-width: 720px) {
        font-size: 15vw;
      }
    }
  }

  .navbar {
    background-color: transparent;
    border-top: 2px dotted #E0CF83;
    display: flex;
    justify-content: space-between;

    padding-top: 50px;
    padding-left: 150px;
    padding-right: 150px;


    @media (screen and  max-width: 720px) {
      img {
        width: 100px;
      }
    }
  }

  .menu {
    display: flex;
    padding-top: 50px;

    a {
      color: $text-color-light;
      text-decoration: none;
      font-size: 18px;
      font-weight: 800;
      text-transform: uppercase;

      &:hover {
        color: white;
      }
    }

    .menu-item:not(:last-child) {
      padding-right: 20px;
    }
  }
}
